import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class StudentService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                public fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS(), data);
    }

    getForProcess(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_FOR_PROCESS(), data);
    }

    getForProcessReport(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_FOR_PROCESS_REPORT(), data);
    }

    getForProcessReportHeadCenter(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_FOR_PROCESS_REPORT_FOR_HEAD_CENTER(), data);
    }

    getProcessingCredits(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.PROCESSING_CREDITS(), data);
    }

    /*getBySession(id, data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_BY_SESSION(id), data);
    }*/

    getByStage(id, data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_BY_STAGE(id), data);
    }

    getByStageForProcess(id, data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_BY_STAGE_FOR_PROCESS(id), data);
    }

    getByStageForProcessReport(id, data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_BY_STAGE_FOR_PROCESS_REPORT(id), data);
    }

    getUnAssignedStudents(data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.UNASSIGNED_STUDENTS(), data);
    }

    count(): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_COUNT());
    }

    admissionCount(data): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_ADMISSION_COUNT(), data);
    }

    reRegisterCount(data): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_RE_REGISTER_COUNT(), data);
    }

    lateralCount(data): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_LATERAL_ENTRY_COUNT(), data);
    }

    creditTransferCount(data): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_CREDIT_TRANSFER_COUNT(), data);
    }

    inProgressCount(): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_IN_PROGRESS_COUNT());
    }

    getNotConnectedCount(): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_NOT_CONNECTED_COUNT());
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS(id));
    }

    detailByCounsellor(id): Observable<any> {
        return this.getRequest(AppUrl.STUDENTS_DETAILS_BY_COUNSELLOR(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS(), data);
    }

    studentRegister(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_REGISTER(), data);
    }

    studentRegisterBusiness(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_REGISTER_BUSINESS(), data);
    }

    studentReRegisterBusiness(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_RE_REGISTER_BUSINESS(), data);
    }

    studentBulkRegister(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_BULK_REGISTER(), data);
    }

    studentBulkProId(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_BULK_PRO_ID(), data);
    }

    bulkReceipt(data): Observable<any> {
        return this.postRequest(AppUrl.BULK_RECEIPT(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENTS(id), data);
    }

    updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_PASSWORD(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_STATUS(id), data);
    }

    assignStudent(data): Observable<any> {
        return this.postRequest(AppUrl.ASSIGN_STUDENTS(), data);
    }

    fileUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }

    studentBulkUpload(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_BULK_UPLOAD(), data);
    }

    studentBulkProIdUpload(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_BULK_PRO_ID_UPLOAD(), data);
    }

    studentBulkReceiptUpload(data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_BULK_RECEIPT_UPLOAD(), data);
    }

    getStudentReceiptUrl(id, data): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.STUDENTS_RECEIPT_URL(id), data);
    }

    studentUpdateStatusByBusiness(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_UPDATE_STATUS_BY_BUSINESS(id), data);
    }

    markProcessableRemark(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_MARK_PROCESSABLE_REMARK(id), data);
    }

    studentUpdateProIdByBusiness(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_UPDATE_PRO_ID_BY_BUSINESS(id), data);
    }

    studentUpdateEnrollmentByBusiness(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_UPDATE_ENROLLMENT_BY_BUSINESS(id), data);
    }

    studentUpdateRollNoByBusiness(id, data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_UPDATE_ROLL_NO_BY_BUSINESS(id), data);
    }

    download(id, data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_DOWNLOAD(id), data);
    }

    downloadStudentPhotograph(id, data): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_PHOTOGRAPH_DOWNLOAD(id), data);
    }

    downloadStudentDocument(id): Observable<any> {
        return this.postRequest(AppUrl.STUDENTS_DOCUMENT_DOWNLOAD(id));
    }

    delete(id): Observable<any> {
        return this.deleteRequest(AppUrl.STUDENT_DELETE(id));
    }

    processStudentByBusiness(data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_PROCESS_BY_BUSINESS(), data);
    }

    processableStudentByBusiness(data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_PROCESSABLE_BY_BUSINESS(), data);
    }

    processedStudentByBusiness(data): Observable<any> {
        return this.putRequest(AppUrl.STUDENT_PROCESSED_BY_BUSINESS(), data);
    }
}
